import {Grid} from "antd";
import "react-phone-input-2/lib/style.css";
import Utils from "../Utils";
import MobileHeader from "../components/Adaptive/Header/MobileHeader";
import FullHeader from "../components/Adaptive/Header/FullHeader";

const {useBreakpoint} = Grid;

export const Header = () => {
  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');
  return isMobile === true ? <MobileHeader /> : <FullHeader />;
};

export default Header;