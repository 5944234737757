import {Button} from "antd";
import {ArrowRightOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom"
import {useEffect, useRef, useState} from "react";
import { useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import {contacts_phone} from "../../../config";
import {ModalFeedback} from "./ModalFeedback";

const logoContainerStyles = {
  borderRadius: '0', width: '15vh', padding: '1vh', margin: 0
};


export const FullHeader = () => {
  const refScrollTop = useRef(null);
  const { pathname } = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    let lastScroll = 0;
    const defaultOffset = 200;
    const menu = document.querySelector('.menu');

    if( menu ) {
      const scrollPosition = () => window.pageYOffset || document.documentElement.scrollTop;
      const containHide = () => menu.classList.contains('hide');

      window.addEventListener('scroll', () => {

        if (scrollPosition() > lastScroll && !containHide() && scrollPosition() > defaultOffset) {
          menu.classList.add('hide');
          //scroll down
        } else if (scrollPosition() < lastScroll && containHide()) {
          menu.classList.remove('hide');
          //scroll up
        }

        lastScroll = scrollPosition();
      })
    }
  },[pathname]);

  let scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
      <div className='menu' ref={refScrollTop}>
        <div>
          <Link to='/'><Button className='buttonMenu' type='text' onClick={scrollToTop}>Главная</Button></Link>
          <Link to='/portfolio'><Button className='buttonMenu' type='text'>Портфолио</Button></Link>
          <Link to='contacts'><Button className='buttonMenu' type='text'>Контакты</Button></Link>
        </div>
        <div className='logoContainer'>
          <Link to='/' onClick={scrollToTop}>
            <img src='/img/Other/Logo.png' alt='logo' style={logoContainerStyles}/>
          </Link>
          <h4 style={{margin: 0, padding: 0}}>Ваша надёжная рекламно-производственная компания!</h4>
        </div>
        <div>
          <span style={{color: 'white', fontSize: '1.7vh'}}>{contacts_phone}</span>
          <Button className='buttonMenu' type='text' onClick={()=>setIsModalOpen(true)}>Обсудить проект<ArrowRightOutlined/></Button>
        </div>

        <ModalFeedback onCancel={()=>setIsModalOpen(false)} open={isModalOpen} />

      </div>
    );
};

export default FullHeader;