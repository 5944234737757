import {useEffect} from "react";
import {Col, Image, Typography, Row} from 'antd';

export const MoreInformation = () => {

  let options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5
  }

  useEffect(() => {

    let callback = function (entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      })
    }

    let observer = new IntersectionObserver(callback, options);

    let targets = document.querySelectorAll('.pictureInfo');

    targets.forEach(target => {
      observer.observe(target);
    });

    let observer2 = new IntersectionObserver(callback, options);

    let targets2 = document.querySelectorAll('.dividingLineMoreInf');

    targets2.forEach(target => {
      observer2.observe(target);
    });
  });

  return (
    <div className='moreInformation'>
      <div className='underMoreInf'>
        <div className='dividingLineMoreInf'/>
        <Typography.Title level={2} style={{padding: '1rem', textAlign: 'center', textTransform: 'uppercase'}}>Наши услуги</Typography.Title>
        <Row gutter={[15,15]} style={{ padding: '10% 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/1.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/11.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={3} style={{ textAlign: "center", paddingBottom: '5rem'}}>
              Специализируемся на реализации стендов любой сложности. Опираясь на
              многолетний опыт, подберем максимально эффективное и технологичное
              решение для реализации проекта.
            </Typography.Title>
          </Col>
        </Row>
        <Row gutter={[15,15]} align={'stretch'} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/22.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/222.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={3} style={{ textAlign: "center", paddingBottom: '5rem'}}>
              Фотозоны, арт-объекты и пресс-воллы.
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/2.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/3.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={3} style={{ textAlign: "center", paddingBottom: '5rem'}}>
              Световые вывески
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/neft_magistral/IMG_5541.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/afisha_of_works/IMG_3855.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={3} style={{ textAlign: "center", paddingBottom: '5rem'}}>
              Широкоформатная печать на высококачественном оборудовании, плоттерная резка и фрезеровка различных материалов
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/5.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/moreInformationImg/55.png'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={3} style={{ textAlign: "center", paddingBottom: '5rem'}}>
              Оформление мероприятий, ивентов и шоу
            </Typography.Title>
          </Col>
        </Row>

        <Row gutter={[15,15]} style={{ padding: '0 20% 5% 20%', margin: 0}}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/veykserf_club_GELENWOOD/IMG_9820.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Image src={'/img/veykserf_club_GELENWOOD/IMG_9886.JPG'} style={{ borderRadius: '5%'}} className={'pictureInfo'}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Typography.Title level={3} style={{ textAlign: "center", paddingBottom: '5rem'}}>
              Изготовление и брендирование металлоконструкций
            </Typography.Title>
          </Col>
        </Row>
      </div>
      <div className='parallax' />
    </div>
  )
};

export default MoreInformation;