import {Button, Col, Row, Grid, Typography} from "antd";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import Utils from "./../Utils";
import {contacts_email, contacts_phone} from "../config";

const {useBreakpoint} = Grid;

export const Footer = () => {

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');

  let options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5
  }

  useEffect(() => {

    let callback = function (entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      })
    }

    let observer = new IntersectionObserver(callback, options);

    let targets = document.querySelectorAll('.dividingLineFooter')
    targets.forEach(target => {
      observer.observe(target);
    });
  });

  let scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return(
    <Row>
      <Col span={24}>
        <div className='footer'>
          <div className='contacts'>
            {isMobile ? <></> : <div className='dividingLineFooter' />}
            <Link to='/'><Button type='text' onClick={scrollToTop}><Typography.Title level={4} className='buttonMenu' style={{fontSize: '4vh'}}>Главная</Typography.Title></Button></Link>
            <Link to='/portfolio'><Button type='text' onClick={scrollToTop}><Typography.Title level={4} className='buttonMenu' style={{fontSize: '4vh'}}>Портфолио</Typography.Title></Button></Link>
            <Link to='/contacts'><Button type='text' onClick={scrollToTop}><Typography.Title level={4} className='buttonMenu' style={{fontSize: '4vh'}}>Контакты</Typography.Title></Button></Link>
            {isMobile ? <></> : <div className='dividingLineFooter' />}
          </div>
          <div className='contactsFooter'>
            <Row direction={"vertical"}>
              <Col span={24} className='footer_number'>
                {contacts_phone}
              </Col>
              <Col span={24} className='footer_numberText'>
                Контактный телефон
              </Col>
            </Row>
            <Row direction={"vertical"}>
              <Col span={24} className='footer_e-mail'>
                {contacts_email}
              </Col>
              <Col span={24} className='footer_e-mailText'>
                E-mail
              </Col>
            </Row>
          </div>
          <Row style={{width: '100%', padding: '1rem'}}>
            <Col span={24}><div className='dividingLineFooter'/></Col>
            <Col span={24}>
              <div className='urInfo'>
                <div style={{padding: '1vh', color: 'gray'}}>
                  &copy; 2024. Рекламно-производственная компания «DecorConcept»
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};