import {Col, Image, Row} from 'antd';
import {useEffect} from "react";
import '../../gallery.css';

const Portfolio = () => {

  useEffect(() => {
    document.title = "Портфолио";
  }, []);

  const events = [
    {
      name: 'Вк квартирник',
      main: '/img/vk_kvartirnik/IMG_0447.JPG',
      items: [
        '/img/vk_kvartirnik/IMG_0447.JPG',
        '/img/vk_kvartirnik/IMG_0450.JPG',
        '/img/vk_kvartirnik/IMG_0449.JPG'
      ]
    },
    {
      name: 'Нефть магистраль',
      main: '/img/neft_magistral/IMG_5534.JPG',
      items: [
        '/img/neft_magistral/IMG_5534.JPG',
        '/img/neft_magistral/IMG_5541.JPG',
        '/img/neft_magistral/IMG_5542.JPG',
      ]
    },
    {
      name: 'Вейксерф-клуб GELENWOOD в Геленджике',
      main: '/img/veykserf_club_GELENWOOD/IMG_9849.JPG',
      items: [
        '/img/veykserf_club_GELENWOOD/IMG_9849.JPG',
        '/img/veykserf_club_GELENWOOD/IMG_9851.JPG',
        '/img/veykserf_club_GELENWOOD/IMG_9856.JPG',
        '/img/veykserf_club_GELENWOOD/IMG_9820.JPG',
        '/img/veykserf_club_GELENWOOD/IMG_9884.JPG',
        '/img/veykserf_club_GELENWOOD/IMG_9886.JPG',
      ]
    },
    {
      name: 'Стрелковый комплекс «Воин», выставка армии, 2024',
      main: '/img/armiya_strelc_compl_voin/IMG_0976 2.JPG',
      items: [
        '/img/armiya_strelc_compl_voin/IMG_0978.JPG',
        '/img/armiya_strelc_compl_voin/IMG_0980 2.JPG',
        '/img/armiya_strelc_compl_voin/IMG_0976 2.JPG'
      ]
    },
    {
      name: 'Выставка логистических компаний "М Лоджистик"',
      main: '/img/m_logistik/IMG_9345.JPG',
      items: [
        '/img/m_logistik/IMG_9345.JPG',
        '/img/m_logistik/IMG_9351.JPG',
        '/img/m_logistik/IMG_9434.JPG',
      ]
    },
    {
      name: 'Ивент "Мартини"',
      main: '/img/martini/IMG_1134.JPG',
      items: [
        '/img/martini/IMG_1134.JPG',
        '/img/martini/IMG_1141.JPG',
        '/img/martini/IMG_1168.JPG',
        '/img/martini/IMG_1169.JPG'
      ]
    },
    {
      name: 'Ивент Авто.ру',
      main: '/img/auto_ru/IMG_0768.JPG',
      items: [
        '/img/auto_ru/IMG_0768.JPG',
        '/img/auto_ru/fdd95ac7-ea87-44b0-9324-aa27095a43e5.JPG',
        '/img/auto_ru/IMG_0765.JPG',
        '/img/auto_ru/IMG_0767.JPG',
        '/img/auto_ru/PHOTO-2023-11-12-14-00-40.JPG'
      ]
    },
    {
      name: 'Ивент ВК в Дербенте',
      main: '/img/event_vk_derbent/IMG_4652.JPG',
      items: [
        '/img/event_vk_derbent/IMG_4652.JPG',
        '/img/event_vk_derbent/648b1994-a320-4031-b4ac-756efba3e040.JPG',
        '/img/event_vk_derbent/afeb6beb-e0e1-4627-84c0-99967fe438b9.JPG',
        '/img/event_vk_derbent/IMG_4653.JPG'
      ]
    },
    {
      name: 'Ивент от Тинькофф и Мега',
      main: '/img/tinkoff_mega/IMG_0379.JPG',
      items: [
        '/img/tinkoff_mega/IMG_0379.JPG',
        '/img/tinkoff_mega/IMG_0378.JPG',
        '/img/tinkoff_mega/IMG_0380.JPG',
        '/img/tinkoff_mega/IMG_0381.JPG',
        '/img/tinkoff_mega/IMG_0383.JPG'
      ]
    },
    {
      name: 'Фестиваль Кардо в Крыму',
      main: '/img/kardo_krym/IMG_1103.JPG',
      items: [
        '/img/kardo_krym/IMG_1105.JPG',
        '/img/kardo_krym/IMG_1102.JPG',
        '/img/kardo_krym/IMG_1103.JPG'
      ]
    },
    {
      name: 'Фестиваль Москва 2030',
      main: '/img/iandex_eda/IMG_1006.JPG',
      items: [
        '/img/iandex_eda/IMG_1006.JPG',
        '/img/iandex_eda/IMG_1004 (1).jpg',
        '/img/iandex_eda/IMG_1005 (1).jpg',
        '/img/iandex_eda/IMG_1007.JPG',
        '/img/iandex_eda/IMG_1008 (1).jpg',
      ]
    },
    {
      name: 'Крипто-форум Umbrella pro billrun, 2024',
      main: '/img/kripto_forum_umbrella/IMG_1256.JPG',
      items: [
        '/img/kripto_forum_umbrella/IMG_1256.JPG',
        '/img/kripto_forum_umbrella/IMG_1255.JPG',
        '/img/kripto_forum_umbrella/IMG_1259.JPG',
        '/img/kripto_forum_umbrella/IMG_1290.JPG',
        '/img/kripto_forum_umbrella/IMG_1293.JPG',
        '/img/kripto_forum_umbrella/IMG_1253.JPG'
      ]
    },
    {
      name: 'Фотозона в саду Эрмитаж',
      main: '/img/fotozone_ermitaje/IMG_1233.JPG',
      items: [
        '/img/fotozone_ermitaje/IMG_1233.JPG',
        '/img/fotozone_ermitaje/IMG_1234.JPG',
        '/img/fotozone_ermitaje/IMG_1235.JPG'
      ]
    },
    {
      name: 'Фотозона ВК',
      main: '/img/fotozone_vk/IMG_1265.JPG',
      items: [
        '/img/fotozone_vk/IMG_1265.JPG',
        '/img/fotozone_vk/IMG_1266.JPG',
        '/img/fotozone_vk/IMG_1267.JPG',
        '/img/fotozone_vk/IMG_1268.JPG',
        '/img/fotozone_vk/IMG_1269.JPG',
        '/img/fotozone_vk/IMG_1270.JPG'
      ]
    },
    {
      name: 'Стенд Mosca',
      main: '/img/stand_Mosca/IMG_1297.JPG',
      items: [
        '/img/stand_Mosca/IMG_1297.JPG',
        '/img/stand_Mosca/IMG_1298.JPG',
        '/img/stand_Mosca/IMG_1301.JPG'
      ]
    },
    {
      name: 'Стенд Galleria Arben',
      main: '/img/stand_Galleria_Arben/IMG_1391.JPG',
      items: [
        '/img/stand_Galleria_Arben/IMG_1391.JPG',
        '/img/stand_Galleria_Arben/IMG_1394.JPG',
        '/img/stand_Galleria_Arben/IMG_1395.JPG',
        '/img/stand_Galleria_Arben/IMG_1393.JPG',
        '/img/stand_Galleria_Arben/IMG_1397.JPG',
        '/img/stand_Galleria_Arben/d4c80773-a201-4d9e-b1b9-99334482199b.JPG'
      ]
    },
  ];

  return(
    <div className='portfolio'>
      <div className='projectsContainer'>
        <h1 className='projects'>Наши реализованные проекты</h1>
        <div className='dividingLine' style={{marginBottom: '10vh'}} />
      </div>
      <div className='containerOfEvents'>
        <Row align="middle">
        {
          events.map(el => {
            return(
              <Col style={{paddingBottom: '7rem'}} xs={24} sm={24} md={12} lg={12} xl={6} xxl={6} key={el.name} align={"center"}>
                <h2 style={{wordBreak: 'normal'}}>{el.name}</h2>
                <Image.PreviewGroup
                  items={
                    el.items
                  }
                >
                  <Image
                    style={{borderRadius: '5%'}}
                    width={200}
                    src={el.items[0]}
                  />
                </Image.PreviewGroup>
              </Col>
            );
          })
        }
        </Row>
      </div>
    </div>
  );

  // return (
  //   <Row align={'middle'}>
  //     <Col span={24} align={'center'}>
  //       <div className="container-all">
  //         { events.map( (item, index) => {
  //           return (
  //             <div className="container" key={'gallery_'+index}>
  //               <Image src={item.main} alt=""/>
  //               <span className="title">{item.name}</span>
  //               {/*<span className="text">Some description there...</span>*/}
  //             </div>
  //           )
  //         })}
  //       </div>
  //     </Col>
  //   </Row>
  // );
};

export default Portfolio;