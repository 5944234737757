import {Row, Col, Grid, Typography} from "antd";
import Utils from "../../Utils";

const {useBreakpoint} = Grid;

export const Introduction = () => {

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');

  return(
    <div className='introduction'>
      <Typography.Title level={2} style={{textAlign: 'center'}}>Изготовление и монтаж декораций</Typography.Title>
      <h5 style={{margin: 0, color: '#cacaca', fontSize: '1.6vh', textAlign: 'center'}}>Полный цикл работ:<br />от проектирования до монтажа</h5>
      <div className='dividingLine' />
      <Row align={"middle"} gutter={15} className='examplesOfWork'>
        <Col align={"center"} xs={12} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <img className='exampleOfWork' src='/img/miniaturesOnHomePage/1.JPG' id='exampleOfWork1' alt='Пример работы'/>
        </Col>
        <Col align={"center"} xs={12} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <img className='exampleOfWork' src='/img/miniaturesOnHomePage/2.jpg' id='exampleOfWork2' alt='Пример работы'/>
        </Col>
        <Col align={"center"} xs={12} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <img className='exampleOfWork' src='/img/miniaturesOnHomePage/3.JPG' id='exampleOfWork3' alt='Пример работы'/>
        </Col>
        <Col align={"center"} xs={12} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <img className='exampleOfWork' src='/img/miniaturesOnHomePage/4.JPG' id='exampleOfWork4' alt='Пример работы'/>
        </Col>
        <Col align={"center"} xs={12} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <img className='exampleOfWork' src='/img/miniaturesOnHomePage/5.JPG' id='exampleOfWork6' alt='Пример работы'/>
        </Col>
        <Col align={"center"} xs={12} sm={12} md={8} lg={8} xl={4} xxl={4}>
          <img className='exampleOfWork' src='/img/miniaturesOnHomePage/6.jpg' id='exampleOfWork7' alt='Пример работы'/>
        </Col>
      </Row>
      {
        isMobile === true
        ?
        <Typography.Title level={1} style={{textAlign: 'center'}} className='nameOfCompany'>DecorConcept</Typography.Title>
        :
        <Typography.Title level={1} style={{textAlign: 'center'}} className='nameOfCompany'>DecorConcept</Typography.Title>
      }
    </div>
  );
};

export default Introduction;