import {Button, Row, Col, Typography} from "antd";
import {FaArrowRightLong} from "react-icons/fa6";
import {Link} from "react-router-dom";

export const ThanksPage = () => {
  return(
    <Row className='thanksPage'>
      <Col span={24}>
        <Typography.Title level={1}  style={{textTransform: 'uppercase', textAlign: 'center', fontSize: '8rem', animation: 'thanksAppear 5s forwards'}}>Спасибо!</Typography.Title>
      </Col>
      <Col span={24}>
        <Typography.Title level={4} style={{textTransform: 'uppercase', textAlign: 'center', marginBottom: '1rem', color: 'lightgray'}}>Ваша заявка на проект успешна отправлена и в скором времени Вам перезвонят с ответом.</Typography.Title>
      </Col>
      <Col>
        <Typography.Title level={3}  style={{textTransform: 'uppercase', textAlign: 'center', color: 'lightgray'}}>А пока, Вы можете ещё раз посмотреть наши проекты</Typography.Title>
      </Col>
      <Col span={24}>
        <Link to='/portfolio'><Button className='showPortfolioBtn' style={{marginBottom: '20vh'}} type='primary'> Смотреть портфолио<FaArrowRightLong /> </Button></Link>
      </Col>
    </Row>
  );
};

export default ThanksPage;