import { FaArrowRightLong } from "react-icons/fa6";
import {Button, Typography, Row, Col, Grid} from "antd";
import {useEffect} from "react";
import {Link} from 'react-router-dom';
import Utils from "../../Utils";

const {useBreakpoint} = Grid;

export const Description = () => {

  const screens = Utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg');

  let options = {
    root: null,
    rootMargin: '5px',
    threshold: 0.5
  }

  useEffect(() => {

    let callback = function (entries, observer) {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      })
    }

    let observer = new IntersectionObserver(callback, options);

    let targets = document.querySelectorAll('.anim')
    targets.forEach(target => {
      observer.observe(target);
    });
  });

  let scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  return(
    <div className='description'>

      <Typography.Title level={2} style={{padding: '1rem', textAlign: 'center', textTransform: 'uppercase'}}>О компании</Typography.Title>
      <Row style={{paddingBottom: '10rem', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          {
            isMobile
              ?
              <img src='/img/afisha_of_works/IMG_0449 2.JPG' alt='фото работы' className='anim picture' id='pic1_mobile'/>
              :
              <img src='/img/afisha_of_works/IMG_0449 2.JPG' alt='фото работы' className='anim picture' id='pic1_full'/>
          }
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Typography.Title level={3} style={{color: 'white', textAlign: 'center'}}>Мы гордимся превращением идей в реальность.
            С нашим многолетним опытом мы обеспечим техническую поддержку мероприятий и их декор.</Typography.Title>
          <Link to='/portfolio' onClick={scrollToTop}><Button className='showPortfolioBtn' type='primary'> Смотреть портфолио<FaArrowRightLong /> </Button></Link>
        </Col>
      </Row>
      <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Col span={24}>
          {
            isMobile
              ?
              <img src='/img/afisha_of_works/2024-10-01vk.jpg' alt='фото работы' className='anim picture' id='pic2_mobile'/>
              :
              <img src='/img/afisha_of_works/2024-10-01vk.jpg' alt='фото работы' className='anim picture' id='pic2_full'/>
          }
        </Col>
        <Col span={24}>
          <Typography.Title level={3} style={{color: 'white', maxWidth: '50rem', height: 'auto', textAlign: 'center'}}>Мы создаём современные декорации, бутафорию, пресс-воллы и фотозоны, а также производим торговую и выставочную мебель.</Typography.Title>
        </Col>
      </Row>
    </div>
  );
};

export default Description;