class Utils {

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */

  static getBreakPoint(screens) {
    let breakpoints = [];

    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }

    return breakpoints;
  };

  static isValidName = (name) => {
    return typeof name === 'string' && name.length && /^[a-zа-я\s]*$/i.test(name);
  };

  static isValidEmail = (email) => {
    return typeof email === 'string' && email.length && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  };
}

export default Utils;