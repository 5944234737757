import {useEffect, useState} from "react";
import {Row, Col, Drawer, Button, Typography} from "antd";
import {ArrowRightOutlined, MenuOutlined} from '@ant-design/icons';
import { Link } from "react-router-dom";
import {contacts_phone} from "../../../config";
import {ModalFeedback} from "./ModalFeedback";

const MobileHeader = () => {

  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    let lastScroll = 0;
    const defaultOffset = 200;
    const menu = document.querySelector('.menu');

    const scrollPosition = () => window.pageYOffset || document.documentElement.scrollTop;
    const containHide = () => menu.classList.contains('hide');

    window.addEventListener('scroll', () => {

      if(scrollPosition() > lastScroll && !containHide() && scrollPosition() > defaultOffset){
        menu.classList.add('hide');
        //scroll down
      } else if(scrollPosition() < lastScroll && containHide()) {
        menu.classList.remove('hide');
        //scroll up
      }

      lastScroll = scrollPosition();
    })
  },[]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  let scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);


  return(
    <Row className='menu'>
      <Col span={14} align={"right"}>
        <Link to='/' onClick={scrollToTop}><img src='/img/Other/Logo.png' alt='logo' style={{maxWidth: 100, }}/></Link>
      </Col>
      <Col span={10} align={"right"}>
        <span><MenuOutlined style={{ cursor: 'pointer', fontSize: 30, padding: '1rem'}} color={"white"} onClick={showDrawer}/></span>
      </Col>
      <Drawer onClose={()=>setOpen(false)} open={open} extra={
        <img src='/img/Other/Logo.png' alt='logo' style={{borderRadius: '0', width: '10vh', padding: '0.5rem', margin: 0}}/>}>
        {/*<div style={{display: 'flex'}}>*/}
          <div style={{position: 'absolute', top: 0}}>
            <p>
              <Link to='/' onClick={()=>setOpen(false)}>
                <Button className='buttonMenuDrawer' type='text' onClick={scrollToTop}>
                  <span onClick={()=>setOpen(false)}>Главная</span>
                </Button>
              </Link>
            </p>
            <p>
              <Link to='/portfolio' onClick={()=>setOpen(false)}>
                <Button className='buttonMenuDrawer' type='text' onClick={scrollToTop}>
                  <span onClick={()=>setOpen(false)}>Портфолио</span>
                </Button>
              </Link>
            </p>
            <p>
              <Link to='contacts' onClick={()=>setOpen(false)}>
                <Button className='buttonMenuDrawer' type='text' onClick={scrollToTop}>
                  <span onClick={()=>setOpen(false)}>Контакты</span>
                </Button>
              </Link>
            </p>
          </div>

          <div style={{position: 'absolute', bottom: '1rem'}}>
            <Typography.Title level={3} style={{fontSize: '1.5rem'}}>{contacts_phone}</Typography.Title>
            <Button
              className='buttonMenuDrawer'
              type='text'
              onClick={()=>setIsModalOpen(true)}
            >
              Обсудить проект <ArrowRightOutlined/>
            </Button>
          </div>
        {/*</div>*/}
      </Drawer>
        <ModalFeedback onCancel={()=>{
          setIsModalOpen(false);
          setOpen(false);
        }} open={isModalOpen}
        />
    </Row>
  );
};

export default MobileHeader;