import {Row, Col, Input, Upload, Form, Modal, Button} from "antd";
import PropTypes from "prop-types";
import {InboxOutlined, MailOutlined, SendOutlined, UserOutlined} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import {useEffect, useState} from "react";
import {MdOutlineEmail} from "react-icons/md";
import _ from "lodash";
import Utils from "../../../Utils";
import {useNavigate} from "react-router";

const FormRules = {
  fieldName: [
    {
      required: true,
      message: 'Пожалуйста, введите имя!',
    }
  ],
  fieldEmail: [
    {
      required: true,
      type: 'email',
      message: 'Пожалуйста, введите e-mail!',
    }
  ],
  fieldPhone: [
    {
      required: true,
      min: 11,
      message: 'Пожалуйста, введите корректный номер телефона!'
    }
  ],
  fieldComment: [
    {
      required: false,
    }
  ],
};

const BACKEND_URL="https://decor-concept.ru/api/feedback";

export const ModalFeedback = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(()=>{
    _.isString(name) && name.length > 0 && Utils.isValidName(name) &&
    _.isString(email) && email.length > 0 && Utils.isValidEmail(email) &&
    _.isString(phone) && phone.length > 0 &&
    _.isString(comment) && comment.length > 0
      ? setIsValid(true)
      : setIsValid(false);
  }, [name, email, phone, comment]);

  const onFinish = (values) => {

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('comment', comment);
    formData.append('file', values.attachment.file);

    fetch(BACKEND_URL, {
      method: 'post',
      body: formData,
      headers: {
        'Accept': 'application/json',
      }
    })
      .then(response => {
        if( response.ok ) {
          navigate('/thanks');
          props?.onCancel();
        }
      })
      .catch(error => {
        console.log('Error: '+error);
        props?.onCancel();
      });
  };

  return (
    <Modal
      open={props.open === true}
      onCancel={props.onCancel}
      title={<><MailOutlined style={{ marginRight: 10}}/>Оставьте заявку на свой проект</>}
      centered
      onFinish={onFinish}
      maskClosable={false}
      footer={null}
    >
        <Row>
          <Col span={24} style={{ marginTop: '1rem'}}>
            <Form name="basic" onFinish={onFinish} autoComplete="off" id='feedback_form' method='post' action={BACKEND_URL}>
              <span style={{color: 'gray'}}>Ваше имя&nbsp;<span style={{color:'red'}}>*</span></span>
              <Form.Item name="username" rules={FormRules.fieldName}>
                <Input onChange={e=>setName(e.target.value)} prefix={<UserOutlined />} allowClear/>
              </Form.Item>

              <span style={{color: 'gray'}}>Адрес эл. почты&nbsp;<span style={{color:'red'}}>*</span></span>
              <Form.Item name="e-mail" rules={FormRules.fieldEmail}>
                <Input onChange={e=>setEmail(e.target.value)} prefix={<MdOutlineEmail />} allowClear/>
              </Form.Item>

              <span style={{color: 'gray'}}>Ваш номер телефона&nbsp;<span style={{color:'red'}}>*</span></span>
              <Form.Item name="phone" rules={FormRules.fieldPhone}>
                <PhoneInput placeholder='+' value={phone} onChange={setPhone} country="ru"/>
              </Form.Item>

              <span style={{color: 'gray'}}>Ваш комментарий&nbsp;<span style={{color:'red'}}>*</span></span>
              <Form.Item name="comment" rules={FormRules.fieldComment}>
                <Input.TextArea onChange={e=>setComment(e.target.value)} autoSize={{minRows: 3, maxRows: 5}} allowClear/>
              </Form.Item>

              <Form.Item name="attachment" valuePropName="attachment">
                <Upload.Dragger
                  accept='.doc, .docx, .xls, .xlsx, .pdf, .rar, .zip'
                  multiple={false}
                  maxCount={1}
                  beforeUpload={()=>false}
                  style={{ width: '100%'}}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Нажмите или перетащите файл в эту область, для загрузки на сервер.
                  </p>
                  <p className="ant-upload-hint">
                    Поддерживаются файлы с расширением: doc, docx,xls, xlsx, pdf, rar, zip не более 10Мб.
                  </p>
                </Upload.Dragger>
              </Form.Item>

              <Row gutter={16}>
                <Col span={24} align='right'>
                <Form.Item style={{ margin: 0}}>
                  <Button block size='large' disabled={!isValid} type='primary' htmlType='submit' icon={<SendOutlined />}>Send</Button>
                </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
    </Modal>
  );
};

ModalFeedback.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};
