import {useEffect} from "react";
import {Typography, Row, Col} from "antd";
import {contacts_email, contacts_phone, contacts_telegram} from "../../config";

const Contacts = () => {

  useEffect(() => {
    document.title = "Контакты";
  }, []);

  return(
    <Row className='contactsPage'>
      <Col span={24}>
        <Typography.Title level={2} style={{textAlign: 'center', fontSize: '4vh', paddingBottom: '5rem'}}>Контакты</Typography.Title>
      </Col>
      <div style={{paddingBottom: '2rem'}}>
        <div className='footer_number' style={{textAlign: 'center'}}>
          {contacts_phone}
        </div>
        <div className='footer_numberText' style={{textAlign: 'center'}}>
          Контактный телефон
        </div>
      </div>

      <div style={{paddingBottom: '2rem'}}>
        <div className='footer_e-mail' style={{textAlign: 'center'}}>
          {contacts_email}
        </div>
        <div className='footer_e-mailText' style={{textAlign: 'center'}}>
          E-mail
        </div>
      </div>

      <div>
        <div className='footer_e-mail' style={{textAlign: 'center'}}>
          <a
            className='buttonMenu'
            style={{textDecoration: 'none !important', color: 'white'}}
            href='https://t.me/decor_concept'
          >
            {contacts_telegram}
          </a>
        </div>
        <div className='footer_e-mailText' style={{textAlign: 'center'}}>
          Telegram
        </div>
      </div>

    </Row>
  )
};

export default Contacts;